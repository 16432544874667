.fullScreenSpinner {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--form-bg-color);
    z-index: 2000;
    margin: 0px;
    text-align: center;
    padding-top: 15%;
    opacity: 0.7;
    left:0;
    top:0
}
