.loginCard {
    margin-top: 10vh;
    background-color: var(--form-bg-color) !important;
}

.line{
    font-size: 1px;
}

.__height{
    max-height: 100vh !important;
    min-height: 100vh !important; 
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.HeaderName{
    width:80px;
    height:80px
}